import { Component, OnInit } from '@angular/core';
import {Location} from '../location';
import {Star} from '../star';

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {
    numStars = 300;
    stars: Star[];

    static getRandNumberBetween(min: number, max: number) {
        return Math.floor(Math.random() * max) + min;
    }

    constructor() {
        this.stars = [];
    }

    ngOnInit() {
        this.generateStars();
    }

    generateStars() {
        let newStar;

        for (let i = 0; i < this.numStars; i++) {
            newStar = new Star(new Location(
                StarsComponent.getRandNumberBetween(0, 100),
                StarsComponent.getRandNumberBetween(0, 100)
            ), i % 10);

            this.stars.push(newStar);
        }
    }
}
