import { Component } from '@angular/core';
import {HomepageLink} from './homepage-link';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    links: Object;

    constructor() {
        this.links = [
            new HomepageLink('NDMLaw.com', '/ndmlaw-angular', 'ndmlaw.png'),
            new HomepageLink('Blog App', '/blogtest', 'blog.png'),
            new HomepageLink('RSS Headline Generator', '/rssgenerator', 'rss.png'),
            new HomepageLink('Twilight Struggle', '/twilight_struggle', 'ts.png'),
            new HomepageLink('Food of the Gods', '/foodofthegods', 'foodofthegods.png'),
            new HomepageLink('Hack/Slash', '/hack-slash', 'hack-slash.png'),
            new HomepageLink('Into the Nest', '/intothenest', 'nest.png'),
            new HomepageLink('Square Plotter', '/square-plotter', 'plotter.png'),
        ];
    }
}
