import {Location} from './location';

export class Star {
    public color: string;

    colors = [
        '#ec5f67',
        '#6699CC',
        '#fac863',
        '#99c794',
        '#FFF',
        '#FFF',
        '#FFF',
    ];

    constructor(public location: Location, public group: number) {
        this.color = this.getRandomColor();
    }

    getRandomColor() {
        const len = this.colors.length - 1;

        return this.colors[Math.floor(Math.random() * len)];
    }
}
